<template>
  <div class="btn" >
    <!-- //  -->
    <img class="tittle_img" :src="title == '服务推荐'?fwphoto:icon" alt="" v-if="title == '服务推荐' || icon">
    <div class="btnNav">
      <div class="btnNavTittle">{{title}}</div>
      <div class="appList" v-if="$route.query.columnType == 'bs'">
        <div class="appItem" v-for="item in pubilcArr" :key="item.id" @click="onlist(item)">
          <img v-lazy="item.columnIcon" alt="">
          <div>{{item.columnName}}</div>
        </div>
      </div>
      <div class="appList" v-else>
        <div class="appItem" v-for="item in pubilcArr" :key="item.id" @click="onlist(item)">
          <img v-lazy="item.columnIcon" alt="">
          <div>{{item.columnName}}</div>
        </div>
      </div>
    </div>
    <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
  </div>
</template>
<script>
import { getServiceMenuList,getServiceRecList,getChildServiceColumn} from '@/api/home.js';
export default {
  name:"btn",
  // props:["pubilcArr"],
  data() {
    return {
      pubilcArr:null,
      title:'',
      icon:'',
      fwphoto:require('@/assets/fuwu.png')
    }
  },
  created(){
    this.init()
  },
  methods:{
      async init(){
          this.title = localStorage.getItem('moresBtn')
          if(this.$store.state.jxbApp){
            window.location.href=`ssptitle://${this.title}`
          }else{
            document.title = this.title
          }
          this.icon = localStorage.getItem('moresUrl')
          if(this.$route.query.columnType == 'bs'){
            let data = await getServiceRecList({
              "pageNumber": 1,
              "pageSize": 90071992
            })
            this.pubilcArr = data.data.data.rows
          }else{
            let data = await getChildServiceColumn({
              "pageNumber": 1,
              "pageSize": 90071992,
              "parentId": this.$route.query.columnId
            })
            this.pubilcArr = data.data.data.rows
          }
      },
      async onlist(item){
        console.log()
        if(item.columnType == 3){
          location.href = item.columnLink
            // lightAppJssdk.weex.openWeexVc({
            //   title :item.columnName,//顶部导航栏标题
            //   url : item.columnLink, //weex页面地址
            //   success: function(data) {
            //       console.log(data);
            //   },
            //   fail: function(data) {
            //       console.log('falied');
            //   }
            //   });
          }else{
              // 导航标题
              this.$store.commit('setTopTittle',item.columnName)
              // 首页数据
              this.$store.commit('setMenuType','leimu')
              // 首页数据
              this.$store.commit('setMenulist',item)
              // 传递id
              this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
          }
      }
  },
  watch:{
  }
}
</script>
<style lang="less" scoped>
.btn{
  .tittle_img{
      width: 100%;
      height: 2.856rem;
    }
  .btnNav{
    padding:0 .249rem;
    box-sizing: border-box;
    margin-bottom: .587rem;
    .btnNavTittle{
      margin-top: .631rem;
      margin-left: .533rem;
      font-size: .48rem;
      color: #000;
      font-weight: 600;
      text-align: left;
    }
    
    .appList{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-between;
    }
    .appItem{
      width: 25%;
      display: flex;
      flex-direction: column;
      margin-top: .72rem;
      text-align: center;
      img{
        width: 1.138rem;
        height: 1.138rem;
        margin: 0.32rem auto;
      }
      div{
        color: #333;
        font-size: 0.34667rem;
      }
    }
  }
  .btnNav:nth-child(1){
    // margin-top: .871rem;
  }
}
</style>